import React, { useContext, useEffect, useState } from "react";
import { IoCloseCircle } from "react-icons/io5";
import { useGetDataQuery } from "../../logic/apiSlice";
import { MdDelete, MdEdit } from "react-icons/md";
import apiService from "../../logic/apiService";
import { NotificationContext } from "../../logic/notifications";

export default function Report({ data }) {
    const [showImage, setShowImage] = useState(false);

    const { isSuccess, data: accountData} = useGetDataQuery("/account/user");

    const [editMode, setEditMode] = useState(false);

    const [content, setContent] = useState(data.content);

    const [timeStamp, setTimeStamp] = useState("");
    const [fullDateWithSeconds, setFullDateWithSeconds] = useState("");

    const NotificationController = useContext(NotificationContext);

    function updateContent() {

        if (content === "")
            return NotificationController.sendMessage("warning", "Content mag niet leeg zijn");
        if (content.length > 4000)
            return NotificationController.sendMessage("warning", "Content mag niet langer zijn dan 4000 karakters");

        apiService.put(`/report/${data.reportID}`, {content: content}).then(res => {
            if (res.success) {
                setEditMode(false);
            }
        })
    }

    function deleteReport() {
        apiService.delete(`/report/${data.reportID}`).then(res => {
            if (res.success) {
                window.location.reload();
            }
        })
    }

    useEffect(() => {
        const timestampDate = new Date(data.dateCreated);
        const now = new Date();

        // Format full date with time (including seconds) in local time zone
        const formattedFullDateWithSeconds = timestampDate.toLocaleString(
            "nl-NL",
            {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
            },
        );
        setFullDateWithSeconds(formattedFullDateWithSeconds);

        // Calculate time difference
        const timeDifference = now.getTime() - timestampDate.getTime();
        const minutes = Math.floor(timeDifference / (1000 * 60));
        const hours = Math.floor(timeDifference / (1000 * 60 * 60));
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

        if (days >= 1) {
            // Show full date after 24 hours
            setTimeStamp(timestampDate.toLocaleDateString("nl-NL"));
        } else if (hours > 0) {
            setTimeStamp(`${hours}u geleden`);
        } else if (minutes > 0) {
            setTimeStamp(`${minutes}m geleden`);
        } else {
            setTimeStamp("Zojuist");
        }
    }, [data]);

    useEffect(() => {
        if (showImage) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
        return () => {
            document.body.style.overflow = "auto";
        };
    }, [showImage]);

    return (
        <div 
            className="animate-fadeIn border-b-light-primary flex min-h-20 w-full max-w-3xl flex-col gap-2.5 border-b px-5 py-3 last:mb-20 last:border-b-0 sm:flex-shrink-0 sm:last:mb-0"
        >
            {/* Content */}
            <div className="flex flex-row gap-2.5">

                {/* Profile Picture */}
                <div className="size-16">
                    {data.residentProfile ? <img className="size-12 rounded-full object-cover aspect-[1/1]" src={`${process.env.NODE_ENV === "development" ? 'http://localhost:3001/api/media' : 'https://familieapp.kwaliteitinhuis.nl/api/media'}/${data.residentProfile}`} alt="Profile" /> : <div className="size-12 rounded-full bg-gray-400"></div>}
                </div>

                {/* Content */}
                <div className="flex w-full flex-col gap-2">

                    {/* Header */}
                    <div className="flex flex-col">

                        {/* Resident Name */}
                        <div className="flex w-full flex-row items-center gap-2">
                            <h5 className="text-base font-bold">
                                {data.residentName}
                            </h5>
                        </div>

                        {/* Date & Promise */}
                        <div className="flex w-full flex-row items-center gap-2">
                            <span
                                className="text-light-text-0.5 sm:text-sm text-xxs"
                                title={fullDateWithSeconds}
                            >
                                {timeStamp}
                            </span>
                            <span className="text-light-text-0.5 sm:text-sm text-xxs">&#8226;</span>
                            <span className="text-light-text-0.5 sm:text-sm text-xxs">{data.promiseName}</span>
                            {isSuccess && [1,2].includes(accountData.permissionID) && (
                                <>
                                    <span className="text-light-text-0.5 sm:text-sm text-xxs">&#8226;</span>
                                    <MdEdit className="text-light-text-0.5 sm:text-sm size-4 cursor-pointer" onClick={() => setEditMode(!editMode)}/>
                                    <span className="text-light-text-0.5 sm:text-sm text-xxs">&#8226;</span>
                                    <MdDelete 
                                        className="text-light-text-0.5 sm:text-sm size-4 cursor-pointer" 
                                        onClick={() => {
                                            if (window.confirm("Are you sure you want to delete this report?")) {
                                            deleteReport();
                                            }
                                        }}
                                        />                                
                                </>
                            )}
                        </div>

                    </div>

                    {/* Body */}
                    <div className="flex w-full max-w-full">

                        {editMode 
                        ?   <div className="w-full flex flex-col">
                                <textarea
                                    className="w-full focus:border-light-secondary break-words bg-light-bg border-light-primary h-32 rounded border p-2 outline-none text-base mb-1"
                                    name="content"
                                    value={content}
                                    onChange={(e) => setContent(e.target.value)}
                                    placeholder="Content"
                                    type="text"
                                    maxLength={4000}
                                />
                                <button
                                    className="bg-light-secondary text-light-text h-8 rounded font-bold w-full"
                                    onClick={() => updateContent()}
                                > Opslaan</button>
                            </div>
                            // Must be 'break-all' will give overflow issues otherwise.
                        : <p className="whitespace-normal break-all text-sm hyphens-auto" lang="nl">{content}</p>
                        }
                    </div>

                    {/* Image */}   
                    <div className="object-contain w-52 overflow-hidden flex justify-center items-center sm:hidden" onClick={() => {data.media && setShowImage(!showImage)}}>
                        {data.media && <img src={`${process.env.NODE_ENV === "development" ? 'http://localhost:3001/api/media' : 'https://familieapp.kwaliteitinhuis.nl/api/media'}/${data.media}`} className={`rounded size-full`}></img>}
                    </div>

                    {showImage &&
                        <div className='z-50 fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex justify-center items-center animate-fadeIn'>
                            <div className="z-50 w-full h-full absolute" onClick={() => setShowImage(false)}><IoCloseCircle className="size-12 fill-white absolute right-0"/></div>
                            <div className="w-10/12 sm:w-96 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                <img src={`${process.env.NODE_ENV === "development" ? 'http://localhost:3001/api/media' : 'https://familieapp.kwaliteitinhuis.nl/api/media'}/${data.media}`} className={`rounded size-full`}></img>
                            </div>
                        </div>
                    }
                </div>

                {/* Image */}   
                <div
                    className="w-3/12 overflow-hidden sm:flex justify-center items-start hidden"
                    onClick={() => {data.media && setShowImage(!showImage)}}
                >
                    {data.media && <img
                    src={`${process.env.NODE_ENV === "development" ? 'http://localhost:3001/api/media' : 'https://familieapp.kwaliteitinhuis.nl/api/media'}/${data.media}`}
                    className="rounded max-w-full max-h-full hover:scale-105 transition-all duration-300 object-contain"
                    alt="media"
                    />}
                </div>

            </div>
        </div>
    );
}