import React, { useState } from "react";

import promise_1 from "../../assets/promiseIcons/1.png"
import promise_2 from "../../assets/promiseIcons/2.png"
import promise_3 from "../../assets/promiseIcons/3.png"
import promise_4 from "../../assets/promiseIcons/4.png"
import promise_5 from "../../assets/promiseIcons/5.png"
import promise_6 from "../../assets/promiseIcons/6.png"
import promise_7 from "../../assets/promiseIcons/7.png"
import promise_8 from "../../assets/promiseIcons/8.png"
import promise_9 from "../../assets/promiseIcons/9.png"
import promise_10 from "../../assets/promiseIcons/10.png"

export default function PromiseCard({ data }) {
    const [showSubPromises, setShowSubPromises] = useState(false);

    return (
        <div 
            className="border-b-light-primary flex min-h-20 w-full max-w-3xl flex-col gap-2.5 border-b px-5 py-3 last:mb-20 last:border-b-0 sm:flex-shrink-0 sm:last:mb-0"
            onClick={() => setShowSubPromises(!showSubPromises)}    
        >
            {/* Content */}
            <div className="flex flex-row gap-2.5">

                {/* Promise Icon */}
                <img className="size-12 object-contain" src={getImage(data.promiseNumber)} alt="Profile" />

                {/* Content */}
                <div className="flex w-full flex-col gap-2">

                    {/* Header */}
                    <div className="flex flex-col">
                        <div className="flex w-full flex-row items-center gap-2">
                            <h5 className="text-base font-bold cursor-pointer">
                                {data.promiseName}
                            </h5>
                        </div>
                        <div className="flex w-full flex-row items-center gap-2">
                            <span className="text-light-text text-sm">
                                Belofte {data.promiseNumber}
                            </span>
                            <span className="text-light-text text-sm">&#8226;</span>
                            <span className="text-light-text text-sm">Klik om de sub-beloftes te zien</span>
                        </div>
                    </div>

                    {/* SubPromises */}
                    <div className={`overflow-hidden flex w-full max-w-full transition-all duration-300 ${showSubPromises ? 'display-block h-14' : 'h-0'}`}>
                        <p className="flex w-full flex-col whitespace-normal break-words text-sm">
                            <li>{data.subPromise_1}</li>
                            <li>{data.subPromise_2}</li>
                            <li>{data.subPromise_3}</li>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

function getImage(promiseNumber) {
    switch (promiseNumber) {
        case 1:
            return promise_1;
        case 2:
            return promise_2;
        case 3:
            return promise_3;
        case 4:
            return promise_4;
        case 5:
            return promise_5;
        case 6:
            return promise_6;
        case 7:
            return promise_7;
        case 8:
            return promise_8;
        case 9:
            return promise_9;
        case 10:
            return promise_10;
        default :
            return null
    }
}