import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { updateTitle } from "../App/redux-reducers/contextProvider";
import { useGetDataQuery } from "../logic/apiSlice";
import { useNavigate } from "react-router-dom";
import apiService from "../logic/apiService";
import PromiseCard from "../components/cards/PromiseCard";
export default function Beloven() {
    const { data: accountData, isSuccess} = useGetDataQuery("/account/user");
    const { data: promises = []} = useGetDataQuery("/promise");
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (![1,2,3,4].includes(accountData.permissionID))
            navigate('/auth/login');
    }, [isSuccess])

    useEffect(() => {
        dispatch(updateTitle("Beloven"));
    }, [])

    return (
        <div className="w-full overflow-scroll no-scrollbar">
            {promises.map(promise => {
                return <PromiseCard key={promise.promiseID} data={promise} />
            })}
        </div>
    )
}