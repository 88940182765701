import React, { useContext, useEffect, useState } from "react";
import { MdSave } from "react-icons/md";
import apiService from "../../logic/apiService";
import { useNavigate, useParams } from "react-router-dom";
import { NotificationContext } from "../../logic/notifications";
import { useDispatch } from "react-redux";
import { updateTitle } from "../../App/redux-reducers/contextProvider";
export default function AdminCreateLocation() {
	const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState({
    name: "",
    contactPerson: "",
    contactPhone: "",
    contactEmail: "",
    address: "",
    houseNumber: "",
    postalCode: "",
    city: "",
  });

  const NotificationController = useContext(NotificationContext);

  function onChange(e) {
    let value = e.target.value;

    setData((prev) => ({
      ...prev,
      [e.target.name]: value,
    }));
  }

  useEffect(() => {
    dispatch(updateTitle("Beheren - Locatie aanmaken"));
  }, []);

  function saveLocation(e) {
    e.preventDefault();

    // Check if all data has a value
    if (Object.values(data).some((x) => x === ""))
      return NotificationController.sendMessage("warning", "Vul alle velden in");

    apiService
      .post(`/admin/application/`, data)
      .then((res) => {
				if (!res.success) return NotificationController.sendMessage("error", "Locatie aanmaken mislukt");
        NotificationController.sendMessage("success", "Locatie aangemaakt");
				navigate(`/beheren/applicatie`);
      });
  }

  return (
    <div className="border-b-light-primary flex min-h-20 w-full max-w-3xl flex-col gap-2.5 border-b px-5 py-3 last:mb-20 last:border-b-0 sm:flex-shrink-0 sm:last:mb-0">
      <h1 className="text-xl">Aanmaken nieuwe locatie</h1>
			<form
        className="overflow-hidden flex-col flex w-full max-w-full transition-all duration-300 display-block h-full"
      >
        
        <p className="text-sm">Naam van locatie</p>
        <input
          className="focus:border-light-secondary bg-light-bg border-light-primary h-8 rounded border p-2 outline-none text-base mb-1"
          name="name"
          value={data.name}
          onChange={(e) => onChange(e)}
          placeholder="Naam"
          type="text"
        />

        <p className="text-sm">Contactpersoon</p>
        <input
          className="focus:border-light-secondary bg-light-bg border-light-primary h-8 rounded border p-2 outline-none text-base mb-1"
          name="contactPerson"
          value={data.contactPerson}
          onChange={(e) => onChange(e)}
          placeholder="Contactpersoon"
          type="text"
        />

        <p className="text-sm">Telefoonnummer contactpersoon</p>
        <input
          className="focus:border-light-secondary bg-light-bg border-light-primary h-8 rounded border p-2 outline-none text-base mb-1"
          name="contactPhone"
          value={data.contactPhone}
          onChange={(e) => onChange(e)}
          placeholder="Telefoonnummer"
          type="text"
        />

        <p className="text-sm">E-mail contactpersoon</p>
        <input
          className="focus:border-light-secondary bg-light-bg border-light-primary h-8 rounded border p-2 outline-none text-base mb-1"
          name="contactEmail"
          value={data.contactEmail}
          onChange={(e) => onChange(e)}
          placeholder="E-mail"
          type="email"
        />

        <p className="text-sm">Adres</p>
        <input
          className="focus:border-light-secondary bg-light-bg border-light-primary h-8 rounded border p-2 outline-none text-base mb-1"
          name="address"
          value={data.address}
          onChange={(e) => onChange(e)}
          placeholder="Adres"
          type="text"
        />

        <p className="text-sm">Huisnummer</p>
        <input
          className="focus:border-light-secondary bg-light-bg border-light-primary h-8 rounded border p-2 outline-none text-base mb-1"
          name="houseNumber"
          value={data.houseNumber}
          onChange={(e) => onChange(e)}
          placeholder="Huisnummer"
          type="text"
        />

        <p className="text-sm">Postcode</p>
        <input
          className="focus:border-light-secondary bg-light-bg border-light-primary h-8 rounded border p-2 outline-none text-base mb-1"
          name="postalCode"
          value={data.postalCode}
          onChange={(e) => onChange(e)}
          placeholder="Postcode"
          type="text"
        />

        <p className="text-sm">Plaats</p>
        <input
          className="focus:border-light-secondary bg-light-bg border-light-primary h-8 rounded border p-2 outline-none text-base mb-1"
          name="city"
          value={data.city}
          onChange={(e) => onChange(e)}
          placeholder="Plaats"
          type="text"
        />



        <div className="flex-col md:flex-row flex justify-between items-center gap-5">
          {/* Save */}
          <button
            type="submit"
            onClick={(e) => saveLocation(e)}
            className={`w-full flex justify-center items-center gap-1 bg-light-secondary text-light-text h-12 px-4 rounded font-bold hover:bg-light-accent transition-all duration-300`}
          >
            <MdSave className="fill-light-text size-5" /> Opslaan
          </button>
        </div>
      </form>
    </div>
  );
}
