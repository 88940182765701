import { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import apiService from "../../logic/apiService";
import { IoIosClose, IoIosCheckmark } from "react-icons/io";
import { NotificationContext } from "../../logic/notifications";

function ChangePassword() {
    const [credentials, setCredentials] = useState({
        password: "",
        confirmPassword: "",
    });

    const NotificationController = useContext(NotificationContext);

    const { token } = useParams()
    const navigate = useNavigate();

    // Create user account
    function handleSubmit(e) {
        e.preventDefault();

        if (Object.values(credentials).some((field) => field === "")) {
            NotificationController.sendMessage("warning", "Fill in all fields.");
            return;
        }

        if (credentials.password !== credentials.confirmPassword) {
            NotificationController.sendMessage("warning", "Passwords do not match.");
            return;
        }

        apiService.post(`/auth/changePassword/${token}`, credentials).then((res) => {
            if (!res.success){
                if (res.message === "INVALID_TOKEN")
                    return NotificationController.sendMessage("error", "Ongeldige token.");
                if (res.message === "TOKEN_EXPIRED")
                    return NotificationController.sendMessage("error", "Token is verlopen.");
                NotificationController.sendMessage("error", "Veranderen van wachtwoord mislukt.");
                return;
            }

            navigate("/beloven");
        });
    }

    // Update credentials state when input changes
    function onChange(e) {
        setCredentials({
            ...credentials,
            [e.target.name]: e.target.value,
        });
    }

    useEffect(() => {
        // Check if token is valid
        apiService.get(`/auth/verify/${token}`).then((res) => {
            if (!res.success) navigate("/auth/login");
        });
    }, [token]);

    return (
        <div className="bg-light-bg text-light-text flex min-h-dvh flex-col items-center justify-center">
            <h1 className="pattaya text-3xl font-bold">Kwaliteit@App</h1>
            <h1 className="pattaya mt-0 mb-5 text-xl font-bold">Wachtwoord wijzigen</h1>

            <form onSubmit={(e) => handleSubmit(e)}>
                <div className="mb-3 flex w-full flex-col items-center justify-center gap-3">
                    <input
                        className="focus:border-light-secondary bg-light-bg border-light-primary h-12 w-72 rounded border p-2 outline-none"
                        name="password"
                        value={credentials.password}
                        onChange={(e) => onChange(e)}
                        placeholder="Wachtwoord"
                        type="password"
                        required
                    />
                    <div
                        className={`grid duration-500 ease-in-out ${credentials.password !== "" ? "grid-rows-animate-height-open" : "grid-rows-animate-height-closed"}`}
                    >
                        <div className="overflow-hidden">
                            <input
                                className={`focus:border-light-secondary bg-light-bg border-light-primary h-12 w-72 rounded border p-2 outline-none`}
                                name="confirmPassword"
                                value={credentials.confirmPassword}
                                onChange={(e) => onChange(e)}
                                placeholder="Bevestig wachtwoord"
                                type="password"
                                required
                            />
                            <div className="mt-2 flex w-72 flex-col">
                                <div
                                    className={`grid duration-300 ease-out ${credentials.password.length >= 8 ? "grid-rows-animate-height-closed mb-0 delay-500" : "grid-rows-animate-height-open mb-1"}`}
                                >
                                    <span
                                        className={`text-light-secondary flex flex-row items-center justify-start overflow-hidden text-sm`}
                                    >
                                        {credentials.password.length >= 8 ? (
                                            <IoIosCheckmark className="size-5 text-green-500" />
                                        ) : (
                                            <IoIosClose className="size-5 text-red-500" />
                                        )}
                                        Wachtwoord moet minimaal 8 karakters lang zijn.
                                    </span>
                                </div>
                                <div
                                    className={`grid duration-300 ease-out ${credentials.password.match(/[A-Z]/) ? "grid-rows-animate-height-closed mb-0 delay-500" : "grid-rows-animate-height-open mb-1"}`}
                                >
                                    <span className="text-light-secondary flex flex-row items-center justify-start overflow-hidden text-sm">
                                        {credentials.password.match(/[A-Z]/) ? (
                                            <IoIosCheckmark className="size-5 text-green-500" />
                                        ) : (
                                            <IoIosClose className="size-5 text-red-500" />
                                        )}
                                        Wachtwoord moet minimaal een hoofdletter bevatten.
                                    </span>
                                </div>
                                <div
                                    className={`grid duration-300 ease-out ${credentials.password.match(/[a-z]/) ? "grid-rows-animate-height-closed mb-0 delay-500" : "grid-rows-animate-height-open mb-1"}`}
                                >
                                    <span className="text-light-secondary flex flex-row items-center justify-start overflow-hidden text-sm">
                                        {credentials.password.match(/[a-z]/) ? (
                                            <IoIosCheckmark className="size-5 text-green-500" />
                                        ) : (
                                            <IoIosClose className="size-5 text-red-500" />
                                        )}
                                        Wachtwoord moet minimaal een kleine letter bevatten.
                                    </span>
                                </div>
                                <div
                                    className={`grid duration-300 ease-out ${credentials.password.match(/[0-9]/) ? "grid-rows-animate-height-closed delay-500" : "grid-rows-animate-height-open"}`}
                                >
                                    <span className="text-light-secondary flex flex-row items-center justify-start overflow-hidden text-sm">
                                        {credentials.password.match(/[0-9]/) ? (
                                            <IoIosCheckmark className="size-5 text-green-500" />
                                        ) : (
                                            <IoIosClose className="size-5 text-red-500" />
                                        )}
                                        Wachtwoord moet minimaal een nummer bevatten.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex w-72 flex-col items-center justify-center gap-3">
                    <button
                        type="submit"
                        className="bg-light-secondary text-light-text h-12 w-full rounded font-bold"
                    >
                        Wijzigen
                    </button>
                    <div className="flex w-full items-center justify-center">
                        <span className="bg-light-primary h-px flex-grow" />
                        <span className="mx-4 -translate-y-px">of</span>
                        <span className="bg-light-primary h-px flex-grow" />
                    </div>
                    <Link
                        to={"/auth/login"}
                        className="border-light-secondary text-light-secondary flex h-12 w-full items-center justify-center rounded border font-bold"
                    >
                        Terug naar inloggen
                    </Link>
                </div>
            </form>
        </div>
    );
}

export default ChangePassword;
