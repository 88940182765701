import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useGetDataQuery } from "../../logic/apiSlice";
import { MdLogout, MdHandshake, MdOutlineHandshake, MdAssignment, MdOutlineAssignment, MdOutlineRemoveRedEye, MdRemoveRedEye, MdBuild, MdOutlineBuild } from "react-icons/md";
function Footer({ logOut }) {
    const location = useLocation();
    let { data: accountData, isSuccess } = useGetDataQuery("/account/user");

    return (
        <div className="flex w-full flex-col items-end sm:w-20 sm:gap-4">
            <div className="border-t-light-primary sm:bg-light-bg-shade bg-light-bg-0.95 fixed bottom-0 z-40 flex h-20 w-full flex-row items-center justify-around border-t sm:relative sm:bottom-auto sm:h-auto sm:w-14 sm:flex-col sm:gap-2 sm:rounded-xl sm:border-0 sm:p-2">
                
                {isSuccess && [1,2,3,4].includes(accountData.permissionID) && <Link
                    to="/beloven"
                    className={`sm:hover:bg-light-bg-0.5 sm:rounded-lg sm:p-1 sm:duration-300`}
                >
                    {location.pathname.includes("/beloven") ? (
                        <MdHandshake className="text-light-secondary size-9" />
                    ) : (
                        <MdOutlineHandshake className="text-light-secondary size-9" />
                    )}
                </Link>}

                {isSuccess && [1,2,3].includes(accountData.permissionID) && <Link
                    to="/beleven"
                    className={`sm:hover:bg-light-bg-0.5 sm:rounded-lg sm:p-1 sm:duration-300`}
                >
                    {location.pathname.includes("/beleven") ? (
                        <MdAssignment className="text-light-secondary size-9" />
                    ) : (
                        <MdOutlineAssignment className="text-light-secondary size-9" />
                    )}
                </Link>}

                {isSuccess && [1,2,3,4].includes(accountData.permissionID) && <Link
                    to="/bekijken"
                    className={`sm:hover:bg-light-bg-0.5 sm:rounded-lg sm:p-1 sm:duration-300`}
                >
                    {location.pathname.includes("/bekijken") ? (
                        <MdRemoveRedEye  className="text-light-secondary size-9" />
                    ) : (
                        <MdOutlineRemoveRedEye  className="text-light-secondary size-9" />
                    )}
                </Link>}

            </div>
            <div className="border-t-light-primary sm:bg-light-bg-shade bg-light-bg-0.95 fixed bottom-0 z-40 hidden h-20 w-full flex-row items-center justify-around border-t sm:relative sm:bottom-auto sm:flex sm:h-auto sm:w-14 sm:flex-col sm:gap-2 sm:rounded-xl sm:border-0 sm:p-2">
                {isSuccess && [1,2].includes(accountData.permissionID) && <Link
                    to={location.pathname.includes(`/beheren`) ? "/beloven" : "/beheren"} 
                    className={`sm:hover:bg-light-bg-0.5 sm:rounded-lg sm:p-1 sm:duration-300`}
                >
                    {location.pathname.includes("/beheren") ? (
                        <MdBuild  className="text-light-secondary size-9" />
                    ) : (
                        <MdOutlineBuild  className="text-light-secondary size-9" />
                    )}
                </Link>}
                <button
                    onClick={() => logOut()}
                    className="sm:hover:bg-light-bg-0.5 hidden sm:flex sm:rounded-lg sm:p-1 sm:duration-300"
                >
                    <MdLogout className="text-light-secondary size-9" />
                </button>
            </div>
        </div>
    );
}

export default Footer;
