import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import apiService from "../../logic/apiService";
import { NotificationContext } from "../../logic/notifications";

export default function Login() {
    const [credentials, setCredentials] = useState({
        email: "",
        password: "",
    });

    const [forgotPassword, setForgotPassword] = useState(false);

    const navigate = useNavigate();

    const NotificationController = useContext(NotificationContext);

    // Authenticate user
    function handleSubmit(e) {
        e.preventDefault();

        if (!credentials.email || !credentials.password) {
            NotificationController.sendMessage("warning", "Fill in all fields.");
            return;
        }

        apiService.post("/auth", credentials).then((res) => {
            if (res.success) {
                navigate("/beloven");
            } else {
                if (res.message === "INVALID_EMAIL_OR_PASSWORD")
                    return NotificationController.sendMessage("error", "Ongeldige e-mail of wachtwoord.");
                return NotificationController.sendMessage("error", "Er is een onbekende fout opgetreden.");
            }
        });
    }

    function resetPassword(e) {
        e.preventDefault();

        apiService.post("/auth/reset/mail", { email: credentials.email }).then((res) => {
            if (res.success) {
                NotificationController.sendMessage("success", "Er is een mail gestuurd om het wachtwoord te resetten.");
            } else {
                if (res.message === "INVALID_EMAIL")
                    return NotificationController.sendMessage("error", "Ongeldig e-mailadres.");
                NotificationController.sendMessage("error", "Er is iets fout gegaan tijdens het versturen van een reset-mail.");
            }
        });
    }

    // Update credentials state when input changes
    function onChange(e) {
        setCredentials({
            ...credentials,
            [e.target.name]: e.target.value,
        });
    }

    function downloadPrivacyAgreement() {
        apiService.get("/auth/privacy-agreement").then((blob) => {
            // Create a URL for the response Blob
            const url = window.URL.createObjectURL(blob);
            
            // Create a link element
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "privacy-agreement.pdf"); // Set the filename
            
            // Append the link to the body
            document.body.appendChild(link);
            
            // Programmatically trigger the download
            link.click();
            
            // Cleanup: remove the link and revoke the object URL
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url); // Free up memory
        }).catch((error) => {
            console.error("Error downloading privacy agreement: ", error);
        });
    }    

    return (
        <div className="bg-light-bg text-light-text flex min-h-dvh flex-col items-center justify-center">
            <h1 className="pattaya my-6 text-3xl font-bold">Kwaliteit@App</h1>

            <form onSubmit={(e) => handleSubmit(e)}>
                <div className={`flex w-full flex-col items-center justify-center ${forgotPassword ? '' : 'gap-3'}`}>
                    <input
                        className="focus:border-light-secondary bg-light-bg border-light-primary h-12 w-72 rounded border p-2 outline-none"
                        name="email"
                        value={credentials.email}
                        onChange={(e) => onChange(e)}
                        placeholder="E-mail"
                        type="email"
                    />
                    {!forgotPassword && <input
                        className="focus:border-light-secondary bg-light-bg border-light-primary h-12 w-72 rounded border p-2 outline-none"
                        name="password"
                        value={credentials.password}
                        onChange={(e) => onChange(e)}
                        placeholder="Wachtwoord"
                        type="password"
                    />}
                </div>
                <div className="flex h-min w-full items-center justify-end pb-5 pt-1">
                    <p className="text-light-accent text-sm hover:underline cursor-pointer" onClick={() => setForgotPassword(!forgotPassword)}>
                        {forgotPassword ? 'Inloggen' : 'Wachtwoord vergeten?'}
                    </p>
                </div>
                <div className="flex w-72 flex-col items-center justify-center gap-3">
                    {!forgotPassword ? <button
                        type="submit"
                        className="bg-light-secondary text-light-text h-12 w-full rounded font-bold"
                    >
                        Login
                    </button>
                    :
                    <button
                        type="button"
                        onClick={(e) => resetPassword(e)}
                        className="bg-light-secondary text-light-text h-12 w-full rounded font-bold"
                    >
                        Mail versturen
                    </button>}
                </div>
            </form>
            <div className="break-words flex w-full justify-center mt-4">
                <p className="text-xxs break-words">
                    Door u aan te melden, gaat u automatisch akkoord met de <span onClick={() => downloadPrivacyAgreement()} className="text-blue-500 hover:underline cursor-pointer">privacyverklaring</span>.
                </p>
            </div>
        </div>
    );
}
