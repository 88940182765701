import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App/App";
import "./index.css";
import store from "./App/store";

import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { NotificationController } from "./logic/notifications";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <BrowserRouter>
        <Provider store={store}>
            <NotificationController>
                <App />
            </NotificationController>
        </Provider>
    </BrowserRouter>,
);
