import React, { useContext, useEffect, useState } from "react";
import { MdSave } from "react-icons/md";
import { useGetDataQuery } from "../../logic/apiSlice";
import apiService from "../../logic/apiService";
import { useNavigate, useParams } from "react-router-dom";
import { NotificationContext } from "../../logic/notifications";
import { useDispatch } from "react-redux";
import { updateTitle } from "../../App/redux-reducers/contextProvider";
export default function AdminCreateBewoner() {
  const navigate = useNavigate();
  const imageInputRef = React.useRef();
  const { data: permissions = [] } = useGetDataQuery("/account/permissions");
  const NotificationController = useContext(NotificationContext);
  const [data, setData] = useState({
    name: "",
    image: "",
    profilepicture_path: "",
  });
  const [uploadingMedia, setUploadingMedia] = useState(false);
  const dispatch = useDispatch();
  const { locationID } = useParams();
  let {
    refetch,
    data: locationData,
    isSuccess: isLocationSuccess,
  } = useGetDataQuery(`/admin/application/location/${locationID}`);

  useEffect(() => {
    function setTitle() {
      if (!isLocationSuccess)
        return dispatch(updateTitle("Beheren - Bewoner aanmaken"));

      if (locationData?.locationID !== parseInt(locationID)) refetch();

      dispatch(updateTitle(`${locationData?.name || "Beheren"} - Bewoner aanmaken`));
    }

    setTitle();
  }, [isLocationSuccess]);
  function onChange(e) {
    let value = e.target.value;

    setData((prev) => ({
      ...prev,
      [e.target.name]: value,
    }));
  }

  function saveResident(e) {
    e.preventDefault();

    if (uploadingMedia) return;

    // Check if all data has a value
    if (data.naam === "")
      return NotificationController.sendMessage(
        "warning",
        "Velden mogen niet leeg gelaten worden."
      );

    apiService.post(`/admin/bewoner/${locationID}`, data).then((res) => {
      if (!res.success)
        return NotificationController.sendMessage(
          "error",
          "Bewoner aanmaken mislukt"
        );
      NotificationController.sendMessage("success", "Bewoner aangemaakt");
      navigate(`/beheren/${locationID}/bewoners`);
    });
  }

  async function uploadImage(e) {
    setUploadingMedia(true);
    const file = e.target.files[0];

    // Is there a file? No? Stop.
    if (!file) {
      setUploadingMedia(false);
      return;
    }

    // Check size
    if (file.size > 4 * 1000 * 1000) {
      // 4MB
      NotificationController.sendMessage(
        "warning",
        "Bestand is te groot, kies een andere afbeelding."
      );
      setUploadingMedia(false);
      return;
    }

    // Check type
    if (!file.type.includes("image")) {
      NotificationController.sendMessage(
        "warning",
        "Bestand is geen afbeelding, kies een andere afbeelding."
      );
      setUploadingMedia(false);
      return;
    }

    // Set image
    setData((prev) => ({
      ...prev,
      image: URL.createObjectURL(file),
    }));

    // Upload image
    const formData = new FormData();
    formData.append("media", file);

    apiService.post(`/admin/bewoner/upload`, formData).then((res) => {
      if (res.success) {
        setData((prev) => ({
          ...prev,
          profilepicture_path: res.path,
        }));
        setUploadingMedia(false);
      } else {
        NotificationController.sendMessage(
          "error",
          "Uploaden van afbeelding mislukt."
        );
        setUploadingMedia(false);
      }
    });
  }

  return (
    <div className="border-b-light-primary flex min-h-20 w-full max-w-3xl flex-col gap-2.5 border-b px-5 py-3 last:mb-20 last:border-b-0 sm:flex-shrink-0 sm:last:mb-0">
      <h1 className="text-xl">Aanmaken nieuwe bewoner</h1>
      <form className="overflow-hidden flex-col flex w-full max-w-full transition-all duration-300 display-block h-full">
        <p className="text-sm">Naam</p>
        <input
          className="focus:border-light-secondary bg-light-bg border-light-primary h-8 rounded border p-2 outline-none text-base mb-1"
          name="name"
          value={data.name}
          onChange={(e) => onChange(e)}
          placeholder="Naam"
          type="text"
          tabIndex={1}
        />

        <div className="w-full my-4">
          <div className="flex w-full flex-row items-center gap-2">
            <span className="text-light-text text-sm">Profielfoto</span>
            <span className="text-light-text text-sm">&#8226;</span>
            <span className="text-light-text text-sm">Klik om te bewerken</span>
          </div>

          {data.image ? (
            <img
              className="max-h-96 object-contain rounded cursor-pointer"
              src={data.image}
              alt="Profile"
              onClick={() => imageInputRef.current.click()}
            />
          ) : (
            <div
              className="w-full h-44 aspect-[1/1] rounded bg-gray-400 animate-pulse cursor-pointer flex justify-center items-center"
              onClick={() => imageInputRef.current.click()}
            >
              <p>Klik hier om een profiel foto te selecteren</p>
            </div>
          )}
          <input
            className="hidden"
            ref={imageInputRef}
            name="image"
            onChange={(e) => uploadImage(e)}
            placeholder="Upload een afbeelding"
            type="file"
            accept="image/*"
          />
        </div>

        <div className="flex-col md:flex-row flex justify-between items-center gap-5">
          {/* Save */}
          <button
            type="submit"
            onClick={(e) => saveResident(e)}
            tabIndex={permissions.length + 4}
            className={`${
              uploadingMedia ? "animate-pulse" : ""
            } w-full flex justify-center items-center gap-1 bg-light-secondary text-light-text h-12 px-4 rounded font-bold hover:bg-light-accent transition-all duration-300`}
          >
            <MdSave className="fill-light-text size-5" /> Opslaan
          </button>
        </div>
      </form>
    </div>
  );
}
