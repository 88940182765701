import React, { useContext, useEffect, useState } from "react";
import { MdAdd, MdDelete, MdEdit, MdLock, MdSave } from "react-icons/md";
import { useGetDataQuery } from "../../logic/apiSlice";
import apiService from "../../logic/apiService";
import { useNavigate, useParams } from "react-router-dom";
import { NotificationContext } from "../../logic/notifications";
import { useDispatch } from "react-redux";
import { updateTitle } from "../../App/redux-reducers/contextProvider";
export default function AdminCreateUser() {
  const navigate = useNavigate();
  const { data: permissions = [] } = useGetDataQuery("/account/permissions");
  const { data: accountData } = useGetDataQuery("/account/user");
  const NotificationController = useContext(NotificationContext);
  const [residents, setResidents] = useState([]);
  const [selectedResident, setSelectedResident] = useState("");
  const dispatch = useDispatch();

  const { locationID } = useParams();

  const [data, setData] = useState({
    permissionID: 3,
    name: "",
    email: "",
    locationRequired: false,
    linkedResidents: [],
    locationID: locationID
  });

  let { refetch, data: locationData, isSuccess: isLocationSuccess} = useGetDataQuery(`/admin/application/location/${locationID}`);

  useEffect(() => {
    function setTitle() {
        if (!isLocationSuccess)
            return dispatch(updateTitle('Beheren - Gebruiker aanmaken'));

        if (locationData?.locationID !== parseInt(locationID))
            refetch();

        dispatch(updateTitle(`${locationData?.name || "Beheren"} - Gebruiker aanmaken`));
    }

    setTitle();
  }, [isLocationSuccess])

  function onChange(e) {
    let value = e.target.value;

    if (e.target.type === "checkbox") value = e.target.checked;

    setData((prev) => ({
      ...prev,
      [e.target.name]: value,
    }));
  }

  useEffect(() => {
    apiService.get(`/admin/bewoner/${locationID}/all`).then((res) => {
      // Filter out all residents that are already linked
      const residents = res.residents.filter(
        (resident) =>
          !data.linkedResidents.some(
            (linkedResident) =>
              parseInt(linkedResident.residentID) ===
              parseInt(resident.residentID)
          )
      );
      setResidents(residents);
    });
  }, []);

  function saveAccount(e) {
    e.preventDefault();
    // Check if all data has a value
    if (Object.values(data).some((field) => field === ""))
      return NotificationController.sendMessage("warning", "Velden mogen niet leeg gelaten worden.");

    if (!data.email.includes("@") || !data.email.includes("."))
      return NotificationController.sendMessage("warning", "Ongeldig e-mailadres");

    apiService.post(`/admin/account/${locationID}`, data).then((res) => {
      if (!res.success)
        if (res.message === "ACCOUNT_ALREADY_EXISTS")
          return NotificationController.sendMessage("error", "Een account met dit e-mailadres bestaat al.");
        else
          return NotificationController.sendMessage("error", "Account aanmaken mislukt.");
      NotificationController.sendMessage("success", "Account aangemaakt");
      navigate(`/beheren/${locationID}/users`);
    });
  }

  function addResident(residentID) {
    if (residentID === "") return;
    const resident = residents.find(
      (resident) => parseInt(resident.residentID) === parseInt(residentID)
    );

    // Filter out the resident from the list
    if (!resident) return;

    setResidents((prev) =>
      prev.filter(
        (resident) => parseInt(resident.residentID) !== parseInt(residentID)
      )
    );

    setData((prev) => ({
      ...prev,
      linkedResidents: [
        ...prev.linkedResidents,
        { residentID: residentID, name: resident.name },
      ],
    }));
    setSelectedResident("");
  }

  function removeResident(residentID) {
    const resident = data.linkedResidents.find(
      (resident) => parseInt(resident.residentID) === parseInt(residentID)
    );

    setData((prev) => ({
      ...prev,
      linkedResidents: prev.linkedResidents.filter(
        (resident) => parseInt(resident.residentID) !== parseInt(residentID)
      ),
    }));

    setResidents((prev) => [...prev, resident]);
  }

  return (
    <div className="border-b-light-primary flex min-h-20 w-full max-w-3xl flex-col gap-2.5 border-b px-5 py-3 last:mb-20 last:border-b-0 sm:flex-shrink-0 sm:last:mb-0">
      <h1 className="text-xl">Aanmaken nieuw account</h1>
      <form className="overflow-hidden flex-col flex w-full max-w-full transition-all duration-300 display-block h-full">
        <p className="text-sm">Naam</p>
        <input
          id="1"
          className="focus:border-light-secondary bg-light-bg border-light-primary h-8 rounded border p-2 outline-none text-base mb-1"
          name="name"
          value={data.name}
          onChange={(e) => onChange(e)}
          placeholder="Naam"
          type="text"
          tabIndex={1}
        />

        <p className="text-sm">E-mail</p>
        <input
          className="focus:border-light-secondary bg-light-bg border-light-primary h-8 rounded border p-2 outline-none mb-1"
          name="email"
          value={data.email}
          onChange={(e) => onChange(e)}
          placeholder="E-mail"
          type="email"
          tabIndex={2}
        />

        <div className="flex items-center gap-1 mb-4">
          <input
            id="locationRequired"
            className="focus:border-light-secondary bg-light-bg border-light-primary size-4 rounded border p-2 outline-none"
            name="locationRequired"
            checked={data.locationRequired}
            onChange={(e) => onChange(e)}
            placeholder="locationRequired"
            type="checkbox"
            tabIndex={3}
          />
          <label htmlFor="locationRequired" className="select-none">
            Moet de gebruiker verplicht op locatie aanwezig zijn?
          </label>
        </div>

        <div className="mb-4">
          <p className="text-sm">Rechtengroep</p>
          {permissions.map((permission, index) => {
            // Users are not allowed to set higher permissions than their own
            if (accountData.permissionID > permission.permissionID) return null;

            return (
              <div
                key={permission.permissionID}
                className="w-full flex items-center justify-start gap-1"
              >
                <input
                  id={`permission-${permission.permissionID}`}
                  className="focus:border-light-secondary bg-light-bg border-light-primary size-4 rounded border p-2 outline-none"
                  name="permissionID"
                  checked={data.permissionID === permission.permissionID}
                  onChange={() =>
                    setData((prev) => ({
                      ...prev,
                      permissionID: permission.permissionID,
                    }))
                  }
                  placeholder="permissionID"
                  type="checkbox"
                  tabIndex={4 + index}
                />
                <label
                  htmlFor={`permission-${permission.permissionID}`}
                  className="select-none"
                >
                  {permission.permissionName}
                </label>
              </div>
            );
          })}

          <div className="w-full flex">
            {data.permissionID === 4 && (
              <div className="flex items-start flex-col w-full gap-1 mb-4">
                <div className="w-full flex flex-col">
                  {/* Bewoner selection */}
                  <p className="text-sm">Bewoner</p>
                  <div className="flex items-center">
                    <select
                      onChange={(e) => setSelectedResident(e.target.value)}
                      className="focus:border-light-secondary bg-light-bg w-full border-light-primary h-10 rounded border p-2 outline-none text-base mb-1"
                      value={selectedResident}
                    >
                      <option value="" disabled defaultValue>
                        Kies een bewoner
                      </option>
                      {residents.map((resident) => (
                        <option
                          key={resident.residentID}
                          value={resident.residentID}
                        >
                          {resident.name}
                        </option>
                      ))}
                    </select>

                    {/* Add Button */}
                    <div className="size-8 flex justify-center items-center cursor-pointer">
                      <MdAdd
                        className="size-6"
                        onClick={() => addResident(selectedResident)}
                      />
                    </div>
                  </div>
                </div>
                {/* Linked Residents */}
                <div className="overflow-scroll no-scrollbar w-full">
                  {data.linkedResidents.map((resident) => (
                    <div
                      key={resident.residentID}
                      className="flex items-center gap-1 justify-between w-full"
                    >
                      <p>{resident.name}</p>
                      <div className="size-8 flex justify-center items-center cursor-pointer">
                        <MdDelete
                          className="size-6"
                          onClick={() => removeResident(resident.residentID)}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="flex-col md:flex-row flex justify-between items-center gap-5">
          {/* Save */}
          <button
            type="submit"
            onClick={(e) => saveAccount(e)}
            tabIndex={permissions.length + 4}
            className="w-full flex justify-center items-center gap-1 bg-light-secondary text-light-text h-12 px-4 rounded font-bold hover:bg-light-accent transition-all duration-300"
          >
            <MdSave className="fill-light-text size-5" /> Opslaan
          </button>
        </div>
      </form>
    </div>
  );
}
