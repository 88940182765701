import React, { useEffect } from "react";
import { Outlet, useLocation, useParams, useNavigate } from "react-router-dom";

import apiService from "../../logic/apiService";
import Footer from "../ui/Footer";
import Header from "../ui/Header";
import { useSelector } from "react-redux";
import AdminFooter from "../ui/AdminFooter";
import { useGetDataQuery } from "../../logic/apiSlice";
function AdminLayout() {
    const title = useSelector((state) => state.context.title);

    const navigate = useNavigate();
    const location = useLocation();
    const { locationID } = useParams();

    const { data: accountData, isSuccess } = useGetDataQuery("/account/user");

    useEffect(() => {
        if (location.pathname === "/beheren") {
            navigate("/beheren/applicatie");
        }

        // Check if url has locationID and if user has permission to view it
        if (isSuccess && accountData.permissionID === 2 && parseInt(locationID) !== accountData.locationID)
            navigate(`/beheren/${accountData.locationID}/users`);

    }, []);

    function logOut() {
        // Clear Cache
        apiService.post("/auth/logout").then(() => {
            navigate("/auth/login");
        });
    }

    return (
        <div className="flex-col sm:relative sm:flex">
            <span className="text-light-text z-50 hidden font-bold sm:fixed sm:flex sm:h-14 sm:w-full sm:items-center sm:justify-center sm:gap-2 sm:text-xl">
                {title}
            </span>
            <div className="bg-light-bg text-light-text flex min-h-dvh flex-col items-center sm:flex-row-reverse sm:items-center sm:justify-center sm:gap-4 sm:py-14 md:gap-6">
                <Header logOut={logOut} />
                <div className="overflow-scroll no-scrollbar sm:bg-light-bg-shade flex w-full flex-col items-center justify-center pt-14 sm:h-[calc(100vh-7rem)] sm:max-w-md sm:justify-start sm:rounded-xl sm:pt-0 sm:shadow-xl md:max-w-lg lg:max-w-xl xl:max-w-2xl 2xl:max-w-3xl">
                    <Outlet />
                </div>
                <AdminFooter logOut={logOut} />
            </div>
        </div>
    );
}

export default AdminLayout;
