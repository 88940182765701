import React, { createContext, useState } from 'react';
// Create the NotificationContext
export const NotificationContext = createContext(null);

/**
 * NotificationController component manages the notifications and provides a way to send new notifications.
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The child components.
 */
export function NotificationController({ children }) {
  const [notifications, setNotifications] = useState([]);

  /**
   * Sends a new notification.
   * @param {Object} settings - The notification settings.
   * @param {string} settings.type - The type of the notification.
   * @param {string} settings.message - The message of the notification.
   * @param {number} [settings.duration=3000] - The duration of the notification in milliseconds.
   * @throws {Error} If the notification type is not set.
   */
  function sendMessage(type, message, duration = 3000) {
    if (!type) throw new Error(`Notification Type not set for notification '${message}'`);

    const id = Date.now(); // Unique ID for each notification
    const newNotification = { id, type, message, duration };

    setNotifications(prevNotifications => [...prevNotifications, newNotification]);

    // Remove the notification after the specified duration
    setTimeout(() => {
      setNotifications(prevNotifications => 
        prevNotifications.filter(notification => notification.id !== id)
      );
    }, duration);
  }

  const context = {
    sendMessage
  };

  return (
    <NotificationContext.Provider value={context}>
      {children}
      <div className="fixed bottom-2.5 left-1/2 transform -translate-x-1/2 w-11/12 sm:w-8/12 m-auto flex flex-col gap-2.5 z-50">
        {notifications.map(notification => (
          <div 
            key={notification.id} 
            id={notification.id} 
            className={`p-2.5 rounded-md text-base break-words overflow-hidden text-ellipsis text-nowrap text-white
              ${notification.type === 'success' ? 'bg-green-500' : notification.type === 'error' ? 'bg-red-500' : notification.type === 'warning' ? 'bg-yellow-500' : 'bg-blue-500'}`}
            onClick={() => {
              setNotifications(prevNotifications => 
                prevNotifications.filter(n => n.id !== notification.id)
              );
            }}
          >
            {notification.message}
          </div>
        ))}
      </div>
    </NotificationContext.Provider>
  );
}