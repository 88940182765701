import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { updateTitle } from "../../App/redux-reducers/contextProvider";
import { useGetDataQuery } from "../../logic/apiSlice";
import { useNavigate, useParams } from "react-router-dom";
import AdminPromiseCard from "../../components/cards/admin/AdminPromiseCard";
import apiService from "../../logic/apiService";
export default function AdminBeloften() {
    const { locationID } = useParams();


    const { data: accountData, isSuccess: isAccountSuccess} = useGetDataQuery("/account/user");
    let { refetch, data: locationData, isSuccess: isLocationSuccess} = useGetDataQuery(`/admin/application/location/${locationID}`);
    const [promises, setPromises] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (isAccountSuccess && ![1,2,3,4].includes(accountData.permissionID))
            navigate('/auth/login');
    }, [isAccountSuccess])

    useEffect(() => {
        function setTitle() {
            if (!isLocationSuccess)
                return dispatch(updateTitle('Beheren - Beloften'));

            if (locationData?.locationID !== parseInt(locationID))
                refetch();

            dispatch(updateTitle(`${locationData?.name || "Beheren"} - Beloften`));
        }

        setTitle();
    }, [isLocationSuccess])

    useEffect(() => {
        apiService.get(`/admin/promise/${locationID}`).then(res => {
            setPromises(res.promises);
        })
    }, [])

    return (
        <div className="w-full overflow-scroll no-scrollbar">
            {promises.map(promise => {
                return <AdminPromiseCard key={promise.promiseID} data={promise} />
            })}
        </div>
    )
}