import React, { useEffect, useRef, useCallback, useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { updateTitle } from '../App/redux-reducers/contextProvider';
import { useGetDataQuery } from '../logic/apiSlice';
import { useNavigate } from 'react-router-dom';
import apiService from '../logic/apiService';
import Report from '../components/cards/ReportCard';
import { FaFilter } from "react-icons/fa"
import { MdClose } from "react-icons/md"

export default function Bekijken() {
    const { data: accountData, isSuccess} = useGetDataQuery("/account/user");
    const { data: promises = []} = useGetDataQuery("/report/promises");
    let { data: residents = []} = useGetDataQuery("/report/residents");
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [reports, setReports] = useState([]);
    const [reportIncrement, setReportIncrement] = useState(1);
    const [hasMore, setHasMore] = useState(true); // Track if there are more reports to load
    const observer = useRef(); // Ref to hold the observer instance
    const [filterMenu, setFilterMenu] = useState(false)


    const [filters, setFilters] = useState({
        residents: [],
        promises: [],
        dates: [],
    })

    useEffect(() => {
        if (![1,2,3,4].includes(accountData.permissionID))
            navigate('/beloven');
    }, [isSuccess])

    useEffect(() => {
        dispatch(updateTitle("Bekijken"));
        fetchReports(1); // Running this will get the first batch of reports
    }, [])

    // Fetch more Reports when reportIncrement changes
    useEffect(() => {
        if (reportIncrement === 1 || !hasMore) return; // Skip the initial page load

        fetchReports(reportIncrement);
    }, [reportIncrement]);

    // Intersection observer callback to detect when the last report is visible
    const lastReportObserver = useCallback(
        (node) => {
            if (observer.current) observer.current.disconnect(); // Disconnect previous observer

            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && hasMore) {
                    setReportIncrement((prevIncrement) => prevIncrement + 1); // Increment page number to load more Reports
                }
            });

            if (node) observer.current.observe(node); // Observe the last report element
        },
        [hasMore],
    );

    // Function to fetch Reports
    const fetchReports = (increment) => {
        apiService.get(`/report/reports/${increment}`, {}).then((res) => {
            if (!res.success) return;

            setReports((prevReports) => [...prevReports, ...res.result]); // Append new Reports directly
            // If fewer than 15 Reports are returned, assume there are no more Reports to load
            if (res.result.length < 15) {
                setHasMore(false);
            }
        });
    };

    function handleFilter(e, filter) {
        const value = e.target.value

        const filterValue = JSON.parse(value);

        setFilters(prev => ({
            ...prev,
            [filter]: [...prev[filter], filterValue]
        }));
    }

    function removeFilter(filter, value) {
        setFilters(prev => ({
            ...prev,
            [filter]: prev[filter].filter((filterValue) => filterValue !== value)
        }))
    }

    return (
        <>
            <div className="no-scrollbar flex w-full flex-col sm:overflow-auto">
                <div className="px-5 py-4 flex gap-2.5 justify-between">

                    {/* Filter */}
                    <button
                        className="size-12 flex justify-center items-center sm:hover:bg-light-bg-0.5 sm:rounded-lg sm:p-1 sm:duration-300"
                        onClick={() => setFilterMenu(!filterMenu)}
                    > 
                        <FaFilter className="text-light-secondary size-8" />
                    </button>

                    {/* Filter List */}
                    <div className="w-full flex justify-start flex-row items-center gap-2 overflow-scroll no-scrollbar">
                        {Object.keys(filters).length !== 0 && Object.keys(filters).map((filter) => {
                            return filters[filter].map((value, index) => {
                                return (
                                    <div key={`${filter}${index}`} onClick={() => removeFilter(filter, value)} className="cursor-pointer hover:px-2 rounded-lg text-nowrap bg-light-primary flex items-center gap-1 h-6 py-0.5 px-1.5 border border-transparent text-sm text-white transition-all shadow-sm">
                                        <span>{value.name || value.promiseName}</span>
                                        <span><MdClose className="size-3.5 hover:size-4"/></span>
                                    </div>
                                )
                            })
                        })}
                    </div>
                </div>

                {/* Filter menu */}
                <div className={`px-5 grid duration-500 ease-in-out ${filterMenu ? "grid-rows-animate-height-open" : "grid-rows-animate-height-closed"}`}>
                    <div className="overflow-hidden w-full flex flex-col sm:flex-row gap-2.5">

                        <div className="w-full flex flex-col">
                            <p className="text-sm">Bewoner</p>
                            <select
                                className="focus:border-light-secondary bg-light-bg border-light-primary h-10 rounded border p-2 outline-none text-base mb-1"
                                name="resident"
                                value="null"
                                onChange={(e) => handleFilter(e, 'residents')}
                                placeholder="Bewoner"
                                type="text"
                            >
                                <option value="null" disabled>Bewoner</option>
                                {residents.map((resident) => (
                                    <option key={resident.residentID} value={JSON.stringify(resident)}>{resident.name}</option>
                                ))}
                            </select>
                        </div>

                        <div className="w-full flex flex-col">
                            <p className="text-sm">Belofte</p>
                            <select
                                className="w-full focus:border-light-secondary bg-light-bg border-light-primary h-10 rounded border p-2 outline-none text-base mb-1 overflow-hidden text-ellipsis"
                                name="promise"
                                value="null"
                                onChange={(e) => handleFilter(e, 'promises')}
                                placeholder="Belofte"
                                type="text"
                            >
                                <option value="null" disabled>Belofte</option>
                                {promises.map((promise) => (
                                    <option key={promise.promiseID} value={JSON.stringify(promise)}>{promise.promiseName}</option>
                                ))}
                            </select>
                        </div>

                        <div className="w-full flex flex-col">
                            <p className="text-sm">Datum</p>
                            <input
                                className="focus:border-light-secondary bg-light-bg border-light-primary h-10 rounded border p-2 outline-none text-base mb-1"
                                name="date"
                                value={""}
                                onChange={(e) => handleFilter({target: {value: JSON.stringify({name: e.target.value})}}, 'dates')}
                                placeholder="Datum"
                                type="date"
                            />
                        </div>
                    </div>
                </div>



                {!reports ? (
                    <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform">
                        <svg
                            aria-hidden="true"
                            className="fill-light-primary h-8 w-8 animate-spin text-gray-200 light:text-gray-600"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                            />
                            <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                            />
                        </svg>
                    </div>
                ) : reports.length === 0 ? (
                    <div className="absolute left-1/2 top-1/2 w-auto -translate-x-1/2 -translate-y-1/2 transform">
                        <h3 className="text-m m-0 w-full text-start font-bold">
                            Geen belevingen gevonden
                        </h3>
                    </div>
                ) : (
                    reports.map((report, index) => {
                        
                        // Check if filters are set, if so check if report matches filters
                        // Filters contains objects including the name and IDS. filter on IDs
                        if (filters.residents.length !== 0 && !filters.residents.some((resident) => resident.residentID === report.residentID)) return;
                        if (filters.promises.length !== 0 && !filters.promises.some((promise) => promise.promiseID === report.promiseID)) return;
                        // Format of report.dateCreated: 2024-10-07T19:18:54.000Z. Make sure to filter on that
                        if (filters.dates.length !== 0 && !filters.dates.some((date) => date.name === report.dateCreated.split("T")[0])) return;


                        if (index === reports.length - 1) {
                            // Attach observer to the last report
                            return (
                                <div key={report.reportID} ref={lastReportObserver}>
                                    <Report data={report} />
                                </div>
                            );
                        }
                        return <Report key={report.reportID} data={report} />;
                    })
                )}
            </div>
        </>
    );
}