import React, { useRef } from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import { useGetDataQuery } from "../logic/apiSlice";

import AuthLayout from "../components/layout/AuthLayout";
import Layout from "../components/layout/Layout";
import AdminLayout from "../components/layout/AdminLayout";

import Login from "../pages/auth/Login";
import ChangePassword from "../pages/auth/ChangePassword.jsx";

import NotFound from "../components/NotFound";

import Beloven from "../pages/Beloven";
import Beleven from "../pages/Beleven";
import Bekijken from "../pages/Bekijken";

import AdminApplication from "../pages/admin/Application";
import AdminUsers from "../pages/admin/Users.jsx";
import AdminCreateUser from "../pages/admin/CreateUser.jsx";
import AdminBewoners from "../pages/admin/Bewoners.jsx";
import AdminBeloften from "../pages/admin/Beloften.jsx";
import AdminCreateBewoner from "../pages/admin/CreateBewoner.jsx";
import AdminCreateLocation from "../pages/admin/CreateLocation.jsx";

function App() {
    return (
        <>
            <Routes>

                <Route path="auth" element={<AuthLayout />}>
                    <Route path="login" element={<Login />} />
                    <Route path="changepassword/:token" element={<ChangePassword />} />
                </Route>
                
                <Route path="/" element={<ProtectedRoute/>}>

                    <Route path="/" element={<Layout />}>
                        <Route path="Beloven"  element={<Beloven  />} />
                        <Route path="Beleven"  element={<Beleven  />} />
                        <Route path="Bekijken" element={<Bekijken />} />
                    </Route>

                    <Route path="beheren" element={<AdminProtectedRoute component={AdminLayout}/>}>
                        <Route path="applicatie" element={<AdminApplication />} />
                        <Route path="location/create" element={<AdminCreateLocation />} />
                        <Route path=":locationID/users" element={<AdminUsers />} />
                        <Route path=":locationID/users/create" element={<AdminCreateUser />} />
                        <Route path=":locationID/bewoners" element={<AdminBewoners />} />
                        <Route path=":locationID/bewoners/create" element={<AdminCreateBewoner />} />
                        <Route path=":locationID/beloften" element={<AdminBeloften />} />
                        <Route path="*" element={<NotFound />} />
                    </Route>
                    
                </Route>

                {/* This route will NOT go through the protectedRoutes middleware. This has been tested and works, i suggest never touching it again. */}
                <Route path="/" element={<Layout />}>
                    <Route path="*" element={<NotFound />} />
                </Route>
            </Routes>
        </>
    );
}



const ProtectedRoute = () => {
    const loadingComponent = useRef(null);
    let { isLoading, isSuccess } = useGetDataQuery("/account/user");

    return (
        <>
            <div
                ref={loadingComponent}
                onAnimationEnd={() => {
                    // Hide the div after the animation ends
                    if (loadingComponent.current) {
                        loadingComponent.current.classList.add("hidden");
                    }
                }}
                className={`bg-light-bg text-light-primary fixed inset-0 z-50 flex items-center justify-center ${isSuccess && "animate-fadeOut"}`}
            >
                <h1 className="my-6 animate-pulse text-3xl font-bold">
                    Kwaliteit @ App
                </h1>
            </div>
            {isSuccess && <Outlet />}
            {!isLoading && !isSuccess && <Navigate to="/auth/login" />}
        </>
    );
};

const AdminProtectedRoute = ({ component: Component }) => {
    let { data: accountData } = useGetDataQuery("/account/user");
    
    // Check if the user has permission permissionID === 1 for admin
    return accountData && [1,2].includes(accountData.permissionID) ? (
        <Component />
    ) : (
        <Navigate to="/beloven" />
    );
};
export default App;
